<template>
  <div v-if="posInfo && posInfo.inventorySet && posInfo.inventorySet.exists">
    <pre>
      {{ JSON.stringify(posInfo.inventorySet.data, null, 4) }}
    </pre>
  </div>
</template>

<script>
export default {
  name: "DetailPosInventory",
  data: () => {
    return {
      posInfo: null
    };
  },
  created() {
    this.axios.get("/api/pos/info").then(resp => {
      this.posInfo = resp.data;
    });
  },
}
</script>
